import React from 'react'
import F1 from './Footers/F1'
import F2 from './Footers/F2'

const Footer = () => {
  return (
    <div>
      <F1/>
      <F2/>
    </div>
  )
}

export default Footer
